import { Injectable, signal } from '@angular/core';

import { Directory } from '@sulser-print/models/directory/directory';
import { QuickFilling } from '@sulser-print/models/quick-filling/quick-filling';

@Injectable()
export class QuickFillingStore {
	private readonly quickFillings = signal<Array<QuickFilling>>([]);

	add(item: QuickFilling) {
		this.quickFillings.update((quickFillings) => [...quickFillings, item]);
	}

	addMultiple(items: Array<QuickFilling>) {
		this.quickFillings.update((quickFillings) => [...quickFillings, ...items]);
	}

	doesQuickFillExist(directoryId: Directory['id'], directoryType: Directory['type']): boolean {
		return this.quickFillings().some((quickFilling) => quickFilling.directoryId === directoryId && quickFilling.type === directoryType);
	}

	empty() {
		this.quickFillings.set([]);
	}

	getQuickFillingsCount() {
		return this.quickFillings().length;
	}

	hasQuickFillings() {
		return this.quickFillings().length > 0;
	}

	remove(quickFillingId: QuickFilling['id']) {
		this.quickFillings.update((quickFillings) => quickFillings.filter((quickFilling) => quickFilling.id !== quickFillingId));
	}

	set(items: Array<QuickFilling>) {
		this.quickFillings.set(items);
	}
}
